<template>
  <div>
    <div v-if="!isLoading" class="box box-default">
      <div class="box-header">
        <div class="flex align-items-center justify-between">
          <h3 class="box-title">PayPal Transactions</h3>
          <div class="flex align-items-center">
            <span class="text-bold text-danger">{{ searching }}</span>
            <input ref="searchInput" v-model="keyword" aria-label="search" class="form-control ml-8 mr-8"
              placeholder="Search..." type="text" @keyup="search($event)" />
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead>
              <tr>
                <th class="pointer" @click="sort('ID')">
                  ID
                  <span v-if="sortKey === 'ID'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('business')">
                  Business
                  <span v-if="sortKey === 'business'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('invoice')">
                  Invoice
                  <span v-if="sortKey === 'invoice'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('payment_date')">
                  Payment Date
                  <span v-if="sortKey === 'payment_date'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('payer_email')">
                  Payer Email
                  <span v-if="sortKey === 'payer_email'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('residence_country')">
                  Residence Country
                  <span v-if="sortKey === 'residence_country'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('payment_status')">
                  Payment Status
                  <span v-if="sortKey === 'payment_status'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('mc_gross')">
                  MC Gross
                  <span v-if="sortKey === 'mc_gross'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('mc_fee')">
                  MC Fee
                  <span v-if="sortKey === 'mc_fee'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('mc_currency')">
                  MC Currency
                  <span v-if="sortKey === 'mc_currency'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('address_name')">
                  Address Name
                  <span v-if="sortKey === 'address_name'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('address_street')">
                  Address Street
                  <span v-if="sortKey === 'address_street'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('address_city')">
                  Address City
                  <span v-if="sortKey === 'address_city'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('address_state')">
                  Address State
                  <span v-if="sortKey === 'address_state'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('address_zip')">
                  Address Zip
                  <span v-if="sortKey === 'address_zip'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('address_country_code')">
                  Address Country Code
                  <span v-if="sortKey === 'address_country_code'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('address_country')">
                  Address Country
                  <span v-if="sortKey === 'address_country'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('payer_id')">
                  Payer ID
                  <span v-if="sortKey === 'payer_id'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('payer_status')">
                  Payer Status
                  <span v-if="sortKey === 'payer_status'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('tax')">
                  Tax
                  <span v-if="sortKey === 'tax'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('payment_type')">
                  Payment Type
                  <span v-if="sortKey === 'payment_type'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('payment_fee')">
                  Payment Fee
                  <span v-if="sortKey === 'payment_fee'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('payment_gross')">
                  Payment Gross
                  <span v-if="sortKey === 'payment_gross'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('charset')">
                  Charset
                  <span v-if="sortKey === 'charset'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('first_name')">
                  First Name
                  <span v-if="sortKey === 'first_name'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('last_name')">
                  Last Name
                  <span v-if="sortKey === 'last_name'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('notify_version')">
                  Notify Version
                  <span v-if="sortKey === 'notify_version'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('custom')">
                  Custom
                  <span v-if="sortKey === 'custom'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('protection_eligibility')">
                  Protection Eligibility
                  <span v-if="sortKey === 'protection_eligibility'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('quantity')">
                  Quantity
                  <span v-if="sortKey === 'quantity'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('verify_sign')">
                  Verify Sign
                  <span v-if="sortKey === 'verify_sign'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('txn_id')">
                  Txn ID
                  <span v-if="sortKey === 'txn_id'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('txn_type')">
                  Txn Type
                  <span v-if="sortKey === 'txn_type'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('receiver_email')">
                  Receiver Email
                  <span v-if="sortKey === 'receiver_email'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('receiver_id')">
                  Receiver ID
                  <span v-if="sortKey === 'receiver_id'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('item_name')">
                  Item Name
                  <span v-if="sortKey === 'item_name'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('item_number')">
                  Item Number
                  <span v-if="sortKey === 'item_number'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('mc_gross_1')">
                  MC Gross 1
                  <span v-if="sortKey === 'mc_gross_1'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('handling_amount')">
                  Handling Amount
                  <span v-if="sortKey === 'handling_amount'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('transaction_subject')">
                  Transaction Subject
                  <span v-if="sortKey === 'transaction_subject'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('shipping')">
                  Shipping
                  <span v-if="sortKey === 'shipping'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('ipn_track_id')">
                  IPN Track ID
                  <span v-if="sortKey === 'ipn_track_id'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('test_ipn')">
                  Test IPN
                  <span v-if="sortKey === 'test_ipn'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="records.length > 0">
                <tr v-for="(record, index) in records" :key="index">
                  <td>{{ record.ID }}</td>
                  <td class="no-wrap">{{ record.business }}</td>
                  <td class="no-wrap">
                    <router-link tag="a"
                      :to="{ name: 'transaction-detail', params: { id: prependPrefix(record.invoice) } }">
                      {{ record.invoice }}</router-link>
                  </td>
                  <td class="no-wrap">{{ record.payment_date }}</td>
                  <td class="no-wrap">{{ record.payer_email }}</td>
                  <td class="no-wrap">{{ record.residence_country }}</td>
                  <td class="no-wrap">{{ record.payment_status }}</td>
                  <td class="no-wrap">{{ record.mc_gross }}</td>
                  <td class="no-wrap">{{ record.mc_fee }}</td>
                  <td class="no-wrap">{{ record.mc_currency }}</td>
                  <td class="no-wrap">{{ record.address_name }}</td>
                  <td class="no-wrap">{{ record.address_street }}</td>
                  <td class="no-wrap">{{ record.address_city }}</td>
                  <td class="no-wrap">{{ record.address_state }}</td>
                  <td class="no-wrap">{{ record.address_zip }}</td>
                  <td class="no-wrap">{{ record.address_country_code }}</td>
                  <td class="no-wrap">{{ record.address_country }}</td>
                  <td class="no-wrap">{{ record.payer_id }}</td>
                  <td class="no-wrap">{{ record.payer_status }}</td>
                  <td class="no-wrap">{{ record.tax }}</td>
                  <td class="no-wrap">{{ record.payment_type }}</td>
                  <td class="no-wrap">{{ record.payment_fee }}</td>
                  <td class="no-wrap">{{ record.payment_gross }}</td>
                  <td class="no-wrap">{{ record.charset }}</td>
                  <td class="no-wrap">{{ record.first_name }}</td>
                  <td class="no-wrap">{{ record.last_name }}</td>
                  <td class="no-wrap">{{ record.notify_version }}</td>
                  <td class="no-wrap">{{ record.custom }}</td>
                  <td class="no-wrap">{{ record.protection_eligibility }}</td>
                  <td class="no-wrap">{{ record.quantity }}</td>
                  <td class="no-wrap">{{ record.verify_sign }}</td>
                  <td class="no-wrap">{{ record.txn_id }}</td>
                  <td class="no-wrap">{{ record.txn_type }}</td>
                  <td class="no-wrap">{{ record.receiver_email }}</td>
                  <td class="no-wrap">{{ record.receiver_id }}</td>
                  <td class="no-wrap">{{ record.item_name }}</td>
                  <td class="no-wrap">{{ record.item_number }}</td>
                  <td class="no-wrap">{{ record.mc_gross_1 }}</td>
                  <td class="no-wrap">{{ record.handling_amount }}</td>
                  <td class="no-wrap">{{ record.transaction_subject }}</td>
                  <td class="no-wrap">{{ record.shipping }}</td>
                  <td class="no-wrap">{{ record.ipn_track_id }}</td>
                  <td class="no-wrap">{{ record.test_ipn }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="42">No record found!</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapGetters } from "vuex";
import { START_LOADING, STOP_LOADING } from "@/store/keys";
import { formatDate, saveNotification } from "@/lib/utils";
export default {
  name: "PayPalTransactions",
  components: {
    Pagination,
  },
  data() {
    return {
      records: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      keyword: "",
      searching: "",
      sortKey: "ID",
      order: "desc",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "currentUser",
    ]),
  },
  methods: {
    updatePage(page) {
      this.getPaginatedRecords(page);
    },
    search(e) {
      if (e.key !== "/" && e.key !== "Control") {
        this.searching = "Searching...";
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.getPaginatedRecords(1);
        }, 500);
      }
    },
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
      this.getPaginatedRecords(1, false);
    },
    getPaginatedRecords(page = 1, isLoading = true) {
      if (isLoading) {
        this.$store.commit(START_LOADING);
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListPayPalTransactions`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword: this.keyword,
          limit: Number.parseInt(this.meta.limit),
          order: this.sortKey,
          orderBy: this.order,
        },
      }).then((response) => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (response.status === 200) {
          this.records = response.data.data.records;
          this.meta = response.data.data.meta;
          if (this.keyword) {
            this.$nextTick(() => this.$refs.searchInput.focus());
          }
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (error.data) {
          this.$toast.fire("", error.data.message, "error");
          saveNotification(error.data.message);
        }
      });
    },
    formatDate(val) {
      return formatDate(val);
    },
    formatBoolean(val) {
      const label = val ? "Yes" : "No";
      const lblClass = val ? "label-success" : "label-danger";
      return `<label class="label ${lblClass}">${label}</label>`;
    },
    handleSlashKey(e) {
      if (e.key === "/") {
        e.preventDefault();
        this.$refs.searchInput.focus();
      }
    },
    prependPrefix(val) {
      return "T" + val.toString().padStart(9, "0");
    },
  },
  mounted() {
    this.getPaginatedRecords();
    window.addEventListener("keydown", this.handleSlashKey);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleSlashKey);
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style scoped></style>
